/* eslint-disable react-hooks/exhaustive-deps */
// React Dependencies
import React, { useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import styled from 'styled-components';

// Types
import { ConfigDataSuccess } from '../../react-query/types';

// Redux
import { useDispatch } from 'react-redux';
import { addNotification } from '../../redux/actions/notification';
import { removePopup, setPopup } from '../../redux/actions/popup';
import { setModal } from '../../redux/actions/modal';

// Layouts
import { ErrorModalLayout, ModalLayout } from './modal-layout';

// Queries
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import useDeleteResource from '../../react-query/hooks/use-delete-resource';
import usePatchMultiResourceWithPayload from '../../react-query/hooks/use-patch-multi-resource-with-payload';

// Configurations
import { CONFIG_BLACKLIST_VISITOR, CONFIG_BLACKLIST_LEVEL } from '../../configurations/resources-config';

// Components
import ConfigTable from '../../components/tables/config-table/config-table';
import Form from '../../components/forms/form';
import { FormStatus } from '../../components/forms/context/form-context';
import { TooltipPopUpSide } from '../../components/tooltip';
import validators from '../../components/forms/validation/validators';

// Enums
import { NotificationMessageType } from '../../enums/notification-types';
import { ButtonThemes } from '../../enums/button-themes';

// Helpers
import { generatePath } from '../../helpers/request-builder';
import { TableFilter } from '../../components/tables/config-table/helpers/table-filter';

// Styles
const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
    margin-top: 20px;
`;

const StyledFormTitle = styled.h3`
    margin: 0 0 10px 0;
`;

const StyledFormText = styled.p`
    margin: 0 0 30px 0;
`;

// Types
type VisitorBlacklistRule = {
    id: number;
    minVisitorId: number;
    maxVisitorId: number;
    level: number;
    levelName: string;
};

type VisitorBlacklistRuleData = VisitorBlacklistRule & {
    selected: boolean;
};

type Option = {
    label: string;
    value: string;
    selected?: boolean;
};

const LayoutModalManageVisitorBlackList = () => {
    const dispatch = useDispatch();

    // Visitor blacklist data
    const [visitorBlacklistRuleData, setVisitorBlacklistRuleData] = useState<VisitorBlacklistRuleData[]>([]);
    const [filteredVisitorBlacklistRuleData, setFilteredVisitorBlacklistRuleData] = useState<
        VisitorBlacklistRuleData[]
    >([]);
    const [visitorBlacklistRuleDataToDelete, setVisitorBlacklistRuleDataToDelete] = useState<number[]>([]);
    const [visitorBlacklistRuleDataToEdit, setVisitorBlacklistRuleDataToEdit] = useState<VisitorBlacklistRule>();

    // Visitor blacklist level
    const [visitorBlacklistLevelOptions, setVisitorBlacklistLevelOptions] = useState<Option[]>([]);

    // Table States
    const [searchValue, setSearchValue] = useState('');
    const [disableTable, setDisableTable] = useState(false);

    // Queries & Mutations
    const visitorBlacklistQuery = useFetchResource({
        resource: CONFIG_BLACKLIST_VISITOR,
        params: [{ key: 'active', value: 1 }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(rule => {
                return {
                    id: rule.id,
                    minVisitorId: rule.visitor_id_min,
                    maxVisitorId: rule.visitor_id_max,
                    level: rule.level.id,
                    levelName: rule.level.name,
                    selected: false,
                };
            });
        },
    });

    const visitorBlacklistLevelQuery = useFetchResource({
        resource: CONFIG_BLACKLIST_LEVEL,
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(level => {
                return {
                    value: level.id,
                    label: level.name,
                };
            });
        },
    });

    const visitorBlacklistRuleDataDeleteMutation = useDeleteResource({
        resource: CONFIG_BLACKLIST_VISITOR,
        resourceIds: visitorBlacklistRuleDataToDelete.map(id => id.toString()),
        handleOnSuccess: () => {
            setDisableTable(false);
            setSearchValue('');
            visitorBlacklistQuery.refetch();
            dispatch(
                addNotification({
                    copy: 'Blacklist Visitor successfully deleted.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            setDisableTable(false);
            setSearchValue('');
            dispatch(
                addNotification({
                    copy: 'There was an issue while deleting your Blacklist Visitors. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const visitorBlacklistRuleDataPatchMutation = usePatchMultiResourceWithPayload({
        resource: CONFIG_BLACKLIST_VISITOR,
        handleOnSuccess: () => {
            setVisitorBlacklistRuleDataToEdit(undefined);
            setDisableTable(false);
            setSearchValue('');
            setFilteredVisitorBlacklistRuleData(
                filteredVisitorBlacklistRuleData.map(rule => ({
                    ...rule,
                    selected: false,
                }))
            );

            dispatch(
                addNotification({
                    copy: 'Visitor Blacklist rule updated successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            setSearchValue('');
            dispatch(
                addNotification({
                    copy: 'There was an issue while saving your Visitor Blacklist rule. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    // Refetch data when the modal is opened
    useEffect(() => {
        visitorBlacklistQuery.refetch();
    }, []);

    // Fetch Fixed Blacklist rule data
    useEffect(() => {
        if (visitorBlacklistQuery.data) {
            setVisitorBlacklistRuleData(visitorBlacklistQuery.data);
            setFilteredVisitorBlacklistRuleData(visitorBlacklistQuery.data);
            setSearchValue('');
        }
    }, [visitorBlacklistQuery.data]);

    // Fetch Fixed Blacklist level data
    useEffect(() => {
        if (visitorBlacklistLevelQuery.data) {
            setVisitorBlacklistLevelOptions(visitorBlacklistLevelQuery.data);
        }
    }, [visitorBlacklistLevelQuery.data]);

    // Update the deleted Fixed Blacklist rule data
    useEffect(() => {
        setVisitorBlacklistRuleDataToDelete(
            filteredVisitorBlacklistRuleData.filter(rule => rule.selected).map(rule => rule.id)
        );
    }, [filteredVisitorBlacklistRuleData]);

    // Handelers
    const handleTableSearch = (searchValue: string) => {
        if (disableTable) return;
        setSearchValue(searchValue);
        const keysToFilter = ['minVisitorId', 'maxVisitorId', 'levelName'] as (keyof VisitorBlacklistRuleData)[];
        setFilteredVisitorBlacklistRuleData(
            TableFilter(visitorBlacklistRuleData, searchValue, keysToFilter) as VisitorBlacklistRuleData[]
        );
    };

    const handleCheckboxChange = (rowId: number) => {
        setFilteredVisitorBlacklistRuleData(
            filteredVisitorBlacklistRuleData.map(rule => {
                if (rule.id === rowId) {
                    return {
                        ...rule,
                        selected: !rule.selected,
                    };
                }

                return rule;
            })
        );
    };

    const handleSingleVisitorBlacklistRuleEditClick = (rowId: number) => {
        const blacklistRule = filteredVisitorBlacklistRuleData.find(rule => rule.id === rowId);

        if (blacklistRule) {
            const blacklistRuleData = {
                id: blacklistRule.id,
                minVisitorId: blacklistRule.minVisitorId,
                maxVisitorId: blacklistRule.maxVisitorId,
                level: blacklistRule.level,
                levelName: blacklistRule.levelName,
            };

            setDisableTable(true);
            setVisitorBlacklistRuleDataToEdit(blacklistRuleData);
        }
    };

    const handleSingleVisitorBlacklistRuleDeleteClick = (rowId: number) => {
        setVisitorBlacklistRuleDataToDelete([rowId]);

        const handleDisardClick = () => {
            setVisitorBlacklistRuleDataToDelete([]);
            dispatch(removePopup());
        };

        const handleDelete = () => {
            setDisableTable(true);
            visitorBlacklistRuleDataDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete this Blacklist Visitor?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleBulkDeleteClick = () => {
        if (visitorBlacklistRuleDataToDelete.length === 0) return;

        const handleDisardClick = () => {
            dispatch(removePopup());
        };

        const handleDelete = () => {
            setFilteredVisitorBlacklistRuleData(
                filteredVisitorBlacklistRuleData.map(rule => {
                    return {
                        ...rule,
                        selected: !rule.selected,
                    };
                })
            );
            setDisableTable(true);
            visitorBlacklistRuleDataDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete the selected Blacklist Visitors?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleSubmitForm = (data: FieldValues) => {
        const formData = data;

        if (
            formData.level !== visitorBlacklistRuleDataToEdit?.level ||
            formData.minVisitorId !== visitorBlacklistRuleDataToEdit?.minVisitorId ||
            formData.maxVisitorId !== visitorBlacklistRuleDataToEdit?.maxVisitorId
        ) {
            setVisitorBlacklistRuleDataToEdit(undefined);

            const payload: FieldValues = {
                resource_uri: generatePath('config', 'blacklist-visitor', formData.id),
                level: generatePath('config', 'blacklist-level', formData.level.value),
                visitor_id_min: formData.minVisitorId,
                visitor_id_max: formData.maxVisitorId,
            };

            visitorBlacklistRuleDataPatchMutation.mutate({
                payload: [payload],
                resourceId: [formData?.id.toString()],
            });
        }
    };

    const handleEditFormCloseClick = () => {
        setVisitorBlacklistRuleDataToEdit(undefined);
        setFilteredVisitorBlacklistRuleData(
            filteredVisitorBlacklistRuleData.map(rule => ({
                ...rule,
                selected: false,
            }))
        );
        setDisableTable(false);
    };

    const handleCreateBlacklistRuleClick = () => {
        dispatch(setModal('CreateIPBlacklistRule', {}));
    };

    if (visitorBlacklistQuery.status === 'success' || visitorBlacklistQuery.status === 'pending') {
        return (
            <ModalLayout modalHeader="Manage Visitor Blacklist">
                <p>Manage previously configured blacklist rules below.</p>

                <ConfigTable
                    status={visitorBlacklistQuery.status}
                    isFetching={visitorBlacklistQuery.isFetching}
                    disabled={disableTable}
                    empty={filteredVisitorBlacklistRuleData.length === 0}
                >
                    <ConfigTable.TableSearch
                        searchValue={searchValue}
                        onTableSearch={handleTableSearch}
                        isDisabled={disableTable}
                    />
                    <ConfigTable.Table maxHeight="400px">
                        <ConfigTable.Header>
                            <ConfigTable.Row key="thead">
                                <ConfigTable.CellHeader />
                                <ConfigTable.CellHeader>Minimum Visitor ID</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader>Maximum Visitor ID</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader>Level</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader />
                            </ConfigTable.Row>
                        </ConfigTable.Header>
                        <ConfigTable.Body>
                            {filteredVisitorBlacklistRuleData.map(rule => {
                                return (
                                    <ConfigTable.Row key={rule.id}>
                                        <ConfigTable.CellCheckbox
                                            rowId={rule.id}
                                            checked={rule.selected}
                                            onCheckedChange={handleCheckboxChange}
                                        />
                                        <ConfigTable.Cell>{rule.minVisitorId}</ConfigTable.Cell>
                                        <ConfigTable.Cell>{rule.maxVisitorId}</ConfigTable.Cell>
                                        <ConfigTable.Cell>{rule.levelName}</ConfigTable.Cell>
                                        <ConfigTable.CellActions>
                                            <ConfigTable.ActionDropdownItem
                                                rowId={rule.id}
                                                type="edit"
                                                onClick={handleSingleVisitorBlacklistRuleEditClick}
                                            />
                                            <ConfigTable.ActionDropdownItem
                                                rowId={rule.id}
                                                type="delete"
                                                onClick={handleSingleVisitorBlacklistRuleDeleteClick}
                                            />
                                        </ConfigTable.CellActions>
                                    </ConfigTable.Row>
                                );
                            })}
                        </ConfigTable.Body>
                    </ConfigTable.Table>
                    <ConfigTable.ActionBar>
                        <ConfigTable.ActionBarJustifyLeft>
                            <ConfigTable.ActionButton
                                type="delete"
                                label="Delete Blacklist Rule"
                                onClick={handleBulkDeleteClick}
                                isDisabled={visitorBlacklistRuleDataToDelete.length === 0}
                            />
                        </ConfigTable.ActionBarJustifyLeft>
                        <ConfigTable.ActionBarJustifyRight>
                            <ConfigTable.ActionButton
                                type="add"
                                label="Create New Blacklist Rule"
                                onClick={handleCreateBlacklistRuleClick}
                                isDisabled={disableTable}
                            />
                        </ConfigTable.ActionBarJustifyRight>
                    </ConfigTable.ActionBar>
                </ConfigTable>

                {/* Edit form */}
                {visitorBlacklistRuleDataToEdit && (
                    <StyledFormContainer>
                        <StyledFormTitle>Edit Rules</StyledFormTitle>
                        <StyledFormText>
                            More information about how what each level will affect your data can be found on our{' '}
                            <a
                                href="http://tag.docs.withcubed.com/onboarding/general/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                support page
                            </a>
                            .
                        </StyledFormText>
                        <Form
                            status={visitorBlacklistLevelQuery.status as FormStatus}
                            onSubmit={handleSubmitForm}
                            defaultValues={visitorBlacklistRuleDataToEdit}
                        >
                            <Form.Body>
                                <Form.Section>
                                    <Form.Field>
                                        <Form.InputLabel
                                            htmlFor="minVisitorId"
                                            label="Minimum Visitor ID"
                                            required={true}
                                            tooltipCopy="This will determine at what point the Cubed system will ignore hits made from this ID range."
                                            tooltipPosition={TooltipPopUpSide.Top}
                                        />
                                        <Form.InputText
                                            name="minVisitorId"
                                            placeholder="Minimum Visitor ID..."
                                            validators={[validators.required]}
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <Form.InputLabel
                                            htmlFor="maxVisitorId"
                                            label="Maximum Visitor ID"
                                            required={true}
                                            tooltipCopy="This will determine at what point the Cubed system will ignore hits made from this ID range."
                                            tooltipPosition={TooltipPopUpSide.Top}
                                        />
                                        <Form.InputText
                                            name="maxVisitorId"
                                            placeholder="Maximum Visitor ID..."
                                            validators={[validators.required]}
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <Form.InputLabel
                                            htmlFor="level"
                                            label="Level"
                                            required={true}
                                            tooltipCopy="Define what to do with visits from your defined blacklist."
                                            tooltipPosition={TooltipPopUpSide.Top}
                                        />
                                        <Form.InputSelect
                                            name="level"
                                            options={visitorBlacklistLevelOptions}
                                            placeholder="Level..."
                                            validators={[validators.required]}
                                        />
                                    </Form.Field>
                                </Form.Section>
                            </Form.Body>
                            <Form.Footer>
                                <Form.InputButton
                                    value="Save"
                                    loading={visitorBlacklistRuleDataPatchMutation.isPending}
                                    disabled={visitorBlacklistRuleDataPatchMutation.isPending}
                                />
                                <Form.InputButton
                                    value="Cancel"
                                    type="button"
                                    onClick={handleEditFormCloseClick}
                                    loading={visitorBlacklistRuleDataPatchMutation.isPending}
                                    disabled={visitorBlacklistRuleDataPatchMutation.isPending}
                                />
                            </Form.Footer>
                        </Form>
                    </StyledFormContainer>
                )}
            </ModalLayout>
        );
    }

    // Page Error
    return <ErrorModalLayout modalHeader="Manage Visitor Blacklist" />;
};

export default LayoutModalManageVisitorBlackList;
